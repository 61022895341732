import React, { useState, Fragment, useEffect } from 'react';
import { useReservContext } from '../../Context/ReservContext';
import { useNavigate, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const SelfSelectDate = () => {
  const { setCDate, setLocations, setHopeHour, setHopeMinute, hospitalOriginKey } = useReservContext();
  const [hopeLocation, setHopeLocation] = useState(''); // 수정: hopeLocation 상태 추가
  const [date3, setDate3] = useState(new Date()); // 희망상담일
  const [openCalendarNum, setOpenCalendarNum] = useState(0); // 수정: openCalendarNum 상태 추가
  const [selectedHour, setSelectedHour] = useState('00');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const location = useLocation();
  const selfUrl = location.state?.selfUrl || '';

  const navigation = useNavigate();

  useEffect(() => {
    //병원 h_key가 없으면 처음 선택화면으로 이동
    if (hospitalOriginKey === '') {
      navigation(`${selfUrl}`);
    }
  }, []);

  const handleBack = () => {
    navigation(-1);
  };

  const moveNext = () => {
    if (!hopeLocation || !date3 || !selectedHour || !selectedMinute) {
      alert('모든 보험상담 정보를 입력해주세요.');
      return;
    }

    setCDate(moment(date3).format('YYYY.MM.DD'));
    setLocations(hopeLocation);
    setHopeHour(selectedHour);
    setHopeMinute(selectedMinute);

    navigation(`${selfUrl}/date2`, {
      state: {
        selfUrl: selfUrl,
      },
    });
  };

  const handleDateChange = (date, calendarNum) => {
    if (calendarNum === 3) {
      setDate3(date);
    }
    setOpenCalendarNum(0); // 달력 닫기
  };
  return (
    <div className="reserv_wrap self">
      <div className="back_btn_box">
        <div className="back_btn" onClick={handleBack}>
          뒤로 이동
        </div>
      </div>
      <div className="reserv_back">
        <div className="reserv_top_box">
          <div className="reserv_title">보험상담일 선택</div>
          <div className="reserv_title sub">희망하시는 일자를 선택하세요.</div>
        </div>
        <div className="reserv_bottom_box">
          <div className="reserv_contents_box">
            <Fragment>
              <div className="reserv_input_box">
                <input
                  type="text"
                  className="reserv_input"
                  placeholder="상담장소"
                  value={hopeLocation}
                  onChange={(e) => setHopeLocation(e.target.value)}
                />
              </div>
              <div className="reserv_input_box calendar">
                <input
                  className="reserv_input calendar"
                  placeholder="희망상담일"
                  value={moment(date3).format('YYYY.MM.DD')}
                  readOnly
                  onClick={() => setOpenCalendarNum(3)}
                ></input>
                {openCalendarNum === 3 && (
                  <Calendar
                    className="reserv_calendar"
                    onChange={(date) => handleDateChange(date, 3)}
                    value={date3}
                    minDate={new Date()}
                  />
                )}
              </div>
              <div className="time_box">
                <div className="time_row">
                  <select
                    className="time_select"
                    value={selectedHour}
                    onChange={(e) => setSelectedHour(e.target.value)}
                  >
                    {Array.from({ length: 24 }, (_, i) => (
                      <option key={i} value={i.toString().padStart(2, '0')}>
                        {i.toString().padStart(2, '0')}
                      </option>
                    ))}
                  </select>
                  시
                </div>
                <div className="time_row">
                  <select
                    className="time_select"
                    value={selectedMinute}
                    onChange={(e) => setSelectedMinute(e.target.value)}
                  >
                    {Array.from({ length: 60 }, (_, i) => (
                      <option key={i} value={i.toString().padStart(2, '0')}>
                        {i.toString().padStart(2, '0')}
                      </option>
                    ))}
                  </select>
                  분
                </div>
              </div>
            </Fragment>
            <div className="reserv_btn_box">
              <div className="reserv_btn" onClick={moveNext}>
                다음 페이지로
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfSelectDate;
