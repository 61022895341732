import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import axios from 'axios';

const RegisterStep1 = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false); //약관관련 모달상태
  const [allTerms, setAllTerms] = useState(false); // 모두 동의 체크박스
  const [reservTerms, setReservTerms] = useState(false); // 예약 서비스 약관 동의
  const [agreeTerms, setAgreeTerms] = useState(false); // 개인정보 약관 동의
  const [marketingTerms, setMarketingTerms] = useState(false); // 마케팅 이용 동의
  const [termsContent, setTermsContent] = useState([]); //모달 content내용 데이터

  const fetchTerms = async (type) => {
    try {
      const response = await axios.get('https://www.wlcmanager.com:8443/api/get/terms_data');
      let termsData;
      if (type === 1) {
        termsData = response.data.serviceInfo;
      } else if (type === 2) {
        termsData = response.data.termsInfo;
      } else if (type === 3) {
        termsData = response.data.marketingInfo;
      }
      setTermsContent(termsData);
    } catch (error) {
      console.error('Error fetching terms:', error);
    }
  };

  const handleSubmit = () => {
    if (!reservTerms) {
      alert('메디코드 통합예약 서비스 이용약관에 동의해주세요.');
      const reservTermsInput = document.getElementById('user_reservTerms');
      if (reservTermsInput) {
        reservTermsInput.focus();
      }
      return;
    } else if (!agreeTerms) {
      alert('개인정보 수집 및 이용약관에 동의해주세요.');
      const agreeTermsInput = document.getElementById('user_agreeTerms');
      if (agreeTermsInput) {
        agreeTermsInput.focus();
      }
      return;
    }

    //마케팅 이용 동의 "Y", "N"상태로 분류
    navigate('/register/step2', {
      state: { marketingTerms: marketingTerms ? 'Y' : 'N' },
    });
  };

  // 모두 동의 체크박스(전체체크)
  const handleAllTerms = (e) => {
    const isChecked = e.target.checked;
    setAllTerms(isChecked);
    setReservTerms(isChecked);
    setAgreeTerms(isChecked);
    setMarketingTerms(isChecked);
  };

  // 약관 개별 체크박스 상태 업데이트
  const handlereservTerms = (e) => {
    setReservTerms(e.target.checked);
  };
  const handleAgreeTerms = (e) => {
    setAgreeTerms(e.target.checked);
  };
  const handleMarketingTerms = (e) => {
    setMarketingTerms(e.target.checked);
  };

  const openModal = (type) => {
    fetchTerms(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="step1-register_wrap">
      <div className="back_btn_box">
        <div className="back_btn" onClick={() => navigate(-1)}>
          {''} 뒤로 이동
        </div>
      </div>
      {/* 본문 시작*/}
      <div className="step1-register-wrap">
        <h1>회원가입을 위해 다음 내용에 동의해주세요</h1>
        <div className="input_rowt terms">
          <input
            type="checkbox"
            checked={allTerms}
            onChange={handleAllTerms}
            id="allTerms"
            className="register_terms_checkbox"
          />
          <label className="terms_label" htmlFor="allTerms">
            모두 동의 <span>(필수 및 선택항목 포함)</span>
          </label>
        </div>
        <div className="input_rowt terms">
          <input
            type="checkbox"
            checked={reservTerms}
            onChange={handlereservTerms}
            id="user_reservTerms"
            className="register_terms_checkbox"
          />
          <label className="terms_label" htmlFor="user_reservTerms">
            메디코드 통합예약 서비스 이용약관 동의 (필수)
          </label>
          <div className="terms-detail-view" onClick={() => openModal(1)} style={{ cursor: 'pointer' }}>
            <span>[ 자세히보기 ]</span>
          </div>
        </div>
        <div className="input_rowt terms">
          <input
            type="checkbox"
            checked={agreeTerms}
            onChange={handleAgreeTerms}
            id="user_agreeTerms"
            className="register_terms_checkbox"
          />
          <label className="terms_label" htmlFor="user_agreeTerms">
            개인정보 수집 및 이용 동의 (필수)
          </label>
          <div className="terms-detail-view" onClick={() => openModal(2)} style={{ cursor: 'pointer' }}>
            <span>[ 자세히보기 ]</span>
          </div>
        </div>
        <div className="input_rowt terms">
          <input
            type="checkbox"
            checked={marketingTerms}
            onChange={handleMarketingTerms}
            id="user_marketingTerms"
            className="register_terms_checkbox"
          />
          <label className="terms_label" htmlFor="user_marketingTerms">
            마케팅 이용 동의
          </label>
          <div className="terms-detail-view" onClick={() => openModal(3)} style={{ cursor: 'pointer' }}>
            <span>[ 자세히보기 ]</span>
          </div>
        </div>

        <div className="terms_btn_box">
          <button type="submit" className="terms_register_btn" onClick={handleSubmit}>
            다음
          </button>
        </div>
      </div>
      {modalOpen && <Modal contents={termsContent} onClose={closeModal} modalOpen={modalOpen} />}
    </div>
  );
};

// 모달 컴포넌트
const Modal = ({ contents, onClose, modalOpen }) => {
  return (
    <div className={`modal-overlay ${modalOpen ? 'open' : ''}`} onClick={onClose}>
      <div className={`modal-content ${modalOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="close-btn-wrap">
          <button className="modal-close" onClick={onClose}>
            X
          </button>
        </div>
        <div className="modal-body" dangerouslySetInnerHTML={{ __html: contents }} />
      </div>
    </div>
  );
};

export default RegisterStep1;
