import React, { useEffect, useRef, useState, Fragment } from 'react';
import TableDefault from '../Table/TableDefault';
import Axios from 'axios';

import { useNavigate, useLocation } from 'react-router-dom';
import { useReservContext } from '../../Context/ReservContext';
import HospitalSelect from '../Hospital/HospitalSelect';
import Loading from '../../Context/Loading';
import { useAuth } from '../../Context/AuthContext';

const SelfSearchHospital = () => {
  const selectRef = useRef(null);
  const {
    setHospitalName,
    setHospitalIdx,
    product,
    setHospitalOriginKey,
    productState,
    productPrice,
    setProduct,
    setProductState,
  } = useReservContext();
  const [hospitalList, setHospitalList] = useState([]); // 병원 리스트
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true); // 로딩 상태를 관리하는 변수 추가
  const navigation = useNavigate();
  const inspectionState = useLocation();
  const selfUrl = inspectionState.state?.selfUrl || '';
  const location = useLocation();
  const sector = location.state?.sector;
  const categoryStatus = inspectionState.state?.categoryStatus || '';
  const reXPathname = selfUrl.replace('/self/', '').replace(/\/$/, '');
  const [branchIdx, setBranchIdx] = useState([]);

  useEffect(() => {
    if (product === '' && categoryStatus !== '') {
      navigation(`${selfUrl}`);
    } else {
      if (branchIdx.length > 0) {
        fetchHospitalList();
      }
    }
  }, [searchData, branchIdx]);

  useEffect(() => {
    const sendData = async () => {
      try {
        const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/self_branch_search', {
          params: { path: reXPathname }, // URL 쿼리 파라미터로 path 전달
        });
        setBranchIdx(response.data.branch_idx);
      } catch (error) {
        console.error('서버 요청 실패:', error);
      }
    };

    sendData();
  }, [reXPathname]);

  const fetchHospitalList = () => {
    let setParams = {};
    let resultApi = '';
    const loginID = branchIdx;

    if (sector === 'sector2') {
      resultApi = 'sector2_hospital';
      console.log('API 호출: sector2_hospital');
    } else if (product !== '' && categoryStatus !== '') {
      setParams.p_key = product;
      resultApi = 'correct_hospital';
      console.log('API 호출: correct_hospital');
    } else {
      resultApi = 'hospital_list';
      console.log('API 호출: hospital_list');
    }

    if (searchData) {
      setParams.searchData = searchData;
    }
    Axios.get(`https://www.wlcmanager.com:8443/api/get/reserv/${resultApi}`, {
      params: { ...setParams, loginID: loginID },
    })
      .then((res) => {
        if (res.data.success) {
          // 서버로부터 받아온 데이터를 rows로 설정합니다.
          setHospitalList(res.data.data || []);
          console.log('가져온데이터', res.data.data);
          console.log('resultApit', resultApi);
          setLoading(false);
        } else {
          alert('해당지역에 병원이 존재하지 않습니다.');
          // console.error("지점 관리 데이터호출 실패");
          if (Object.keys(searchData).length !== 0 && !res.data.success) {
            alert('검색조건에 맞는 데이터가 없습니다.');
            selectRef.current.clearSearch();
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error('지점 관리 데이터호출 실패:', err);
      });
  };

  const columns = [
    {
      field: 'check_btn',
      headerName: '선택',
      type: 'actions',
      renderCell: (params) => (
        <div className="table_inner_btn reserv" onClick={() => selectRowData(params.row)}>
          선택하기
        </div>
      ),
    },
    // { field: "id", headerName: "No", flex: 0.5 },
    { field: 'name', headerName: '병원명' },
    { field: 'province', headerName: '지역(시/도)' },
    // { field: "city", headerName: "지역(구/군)" },
  ];

  const rows = (hospitalList || []).map((data, index) => ({
    id: index + 1,
    idx: data.idx,
    p_key: data.p_key,
    name: data.name,
    province: data.province,
    city: data.city,
    h_key: data.h_key,
  }));

  const emptyFunc = () => {};
  const selectRowData = (data) => {
    setHospitalName(data.name);
    setHospitalIdx(data.idx);
    setHospitalOriginKey(data.h_key);
    if (product !== '') {
      if (productState === '1') {
        navigation(`${selfUrl}/date`, {
          state: {
            selfUrl: selfUrl,
          },
        });
      } else if (productState === '2') {
        navigation(`${selfUrl}/date2`, {
          state: {
            selfUrl: selfUrl,
          },
        });
      }
    } else {
      navigation(`${selfUrl}/product`, {
        state: { firsts: '1', selfUrl: selfUrl, hospital: data },
      });
    }
    // if (product !== "") {
    // navigation(`${selfUrl}/date`);
    // } else {
    //   navigation(`${selfUrl}/product`);
    // }
  };
  const handleBack = () => {
    setProduct('');
    setProductState('');
    navigation(-1);
  };
  return (
    <div className="reserv_wrap self">
      {/* <div className="back_btn_box self">
        <div className="back_btn" onClick={() => navigation(-1)}>뒤로 이동</div>
      </div> */}
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="back_btn_box">
            <div className="back_btn" onClick={handleBack}>
              뒤로 이동
            </div>
          </div>
          <div className="reserv_back main">
            <div className="reserv_top_box">
              <div className="reserv_title">병원목록</div>
              <div className="reserv_title sub">검진 가능한 병원들을 확인합니다.</div>
            </div>
            <div className="main_wrap">
              <div className="main_back">
                <div className="board_list_wrap">
                  <div className="list_area reserv">
                    {/* <div className="search_box">
                      <HospitalSelect ref={selectRef} setSearchData={setSearchData}></HospitalSelect>
                    </div> */}
                    <div className="table_box">
                      <TableDefault rows={rows} columns={columns} viewModalOpen={emptyFunc} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SelfSearchHospital;
