import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SelfSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selfUrl = location.state?.selfUrl || '';
  return (
    <div className="reserv_wrap self">
      <div className="reserv_back success">
        <div className="reserv_center_box">
          <div className="center_icon_box">
            <div className="center_icon"></div>
            <div className="center_text">예약완료</div>
          </div>
          <div className="center_info_box">예약이 성공적으로 완료되었습니다.</div>
          <div className="center_home_box" onClick={() => navigate(`${selfUrl}`)}>
            홈으로 돌아가기
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfSuccess;
