import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const InspectionViewModal = (props) => {
  const [detailNum, setDetailNum] = useState({});
  const [isChecked, setIsChecked] = useState(false); // 예약유무체크
  const [memo, setMemo] = useState(''); // 비고
  const [uid, setUid] = useState('');
  const [branchIdx, setBranchIdx] = useState('');
  const [contractStatus, setContractStatus] = useState(''); // 계약 상태
  const [hopeStatus, setHopeStatus] = useState('');
  const [consultingDate, setConsultingDate] = useState(new Date()); // 상담희망일자
  const [consultingTime, setConsultingTime] = useState(dayjs().format('HH:mm')); // 상담희망시간
  const [openCalendar, setOpenCalendar] = useState(false); // 달력 오픈
  const [resultCalendar, setResultCalendar] = useState(new Date()); // 검진확정일 달력 데이터

  const calendarStatus = () => {
    setOpenCalendar(!openCalendar);
  };

  const clockHandle = (newValue) => {
    const formattedTime = dayjs(newValue).format('HH:mm');
    setConsultingTime(formattedTime);
  };

  useEffect(() => {
    if (props.detailIdx) {
      const { idx, memo, hope_status, consulting_date, consulting_time, contract, ...otherDetails } = props.detailIdx;

      // 상태 업데이트
      setDetailNum({ idx, ...otherDetails });
      setMemo(memo);
      setHopeStatus(hope_status);
      setContractStatus(contract);
      setConsultingDate(new Date(consulting_date));
      setConsultingTime(consulting_time);

      // 선택된 게시글의 인덱스를 찾음
      const selectedIndex = props.arrayData.findIndex((data) => data.idx === idx);

      if (selectedIndex !== -1) {
        setUid(props.arrayData[selectedIndex].uid);
        setBranchIdx(props.arrayData[selectedIndex].branch_idx);
      }
    }
  }, [props.detailIdx]);

  const setFormatDate = (date) => {
    setConsultingDate(date);
    setResultCalendar(date);
    setOpenCalendar(false);
  };

  const saveContractStatus = async (newStatus) => {
    try {
      const response = await Axios.post('https://www.wlcmanager.com:8443/api/post/inspect_status', {
        idx: detailNum.idx,
        contract: newStatus,
        uid: uid,
        branchIdx: branchIdx,
      });
      console.log(response.data);
      setContractStatus(newStatus); // 상태 업데이트
    } catch (error) {
      console.error('Error saving contract status', error);
    }
  };

  const handleContractChange = (e) => {
    const newStatus = e.target.value;
    if (window.confirm(`[${detailNum.name}]님의 계약상태를 변경하시겠습니까?`)) {
      saveContractStatus(newStatus);
    }
  };

  const handleSubmit = () => {
    Axios.post('https://www.wlcmanager.com:8443/api/post/updateMemo', {
      detailIdx: detailNum.idx,
      memo: memo,
      uid: uid,
      consulting_date: moment(consultingDate).format('YYYY-MM-DD'),
      consulting_time: consultingTime,
    })
      .then((res) => {
        console.log(res);
        alert('메모가 업데이트되었습니다.');
        props.closeModal();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const clearModal = () => {
    props.closeModal();
  };

  return (
    <div className="modal_wrap">
      <div className="modal_back">
        <div className="modal_box">
          <div className="modal_title_box">
            <div className="modal_title">보험점검 예약관리 상세</div>
            <div className="modal_close_btn" onClick={clearModal}>
              X
            </div>
          </div>
          <div className="table_box">
            <div className="table_row">
              <div className="table_section half">
                <div className="table_title">예약자성명</div>
                <div className="table_contents w100">{detailNum.contractor_name}</div>
              </div>
              <div className="table_section half">
                <div className="table_title">검진자성명</div>
                <div className="table_contents w100">{detailNum.name}</div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section half">
                <div className="table_title">예약자 연락처</div>
                <div className="table_contents w100">{detailNum.phone}</div>
              </div>
              <div className="table_section half">
                <div className="table_title">검진자 연락처</div>
                <div className="table_contents w100">{detailNum.phone_2}</div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section">
                <div className="table_title">검진자 주소</div>
                <div className="table_contents w100">{detailNum.address}</div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section half">
                <div className="table_title">가입일</div>
                <div className="table_contents w100">{detailNum.date}</div>
              </div>
              <div className="table_section half">
                <div className="table_title">선택상품</div>
                <div className="table_contents w100">{detailNum.product}</div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section half">
                <div className="table_title">검진병원</div>
                <div className="table_contents w100">{detailNum.hospital}</div>
              </div>
              <div className="table_section half">
                <div className="table_title">검진일</div>
                <div className="table_contents w100">{detailNum.result_date}</div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section half">
                <div className="table_title">상담희망</div>
                <div className="table_contents w100">{hopeStatus}</div>
              </div>
              <div className="table_section half">
                <div className="table_title">계약</div>
                <div className="table_contents w100">
                  <div className="table_radio">
                    <input type="radio" value="Y" checked={contractStatus === 'Y'} onChange={handleContractChange} /> Y
                  </div>
                  <div className="table_radio">
                    <input type="radio" value="N" checked={contractStatus === 'N'} onChange={handleContractChange} /> N
                  </div>
                </div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section half">
                <div className="table_title">상담희망일자</div>
                <div className="table_contents w100 calendar">
                  <input
                    className="table_input w100"
                    type="text"
                    placeholder="확정일 입력해주세요."
                    value={moment(consultingDate).format('YYYY.MM.DD')}
                    onClick={calendarStatus}
                    readOnly
                  />
                  {openCalendar && (
                    <Calendar
                      onChange={(e) => setFormatDate(e)}
                      value={resultCalendar}
                      formatDay={(locale, date) => moment(date).format('DD')}
                      minDate={moment().toDate()}
                      calendarType="gregory"
                    />
                  )}
                </div>
              </div>
              <div className="table_section half">
                <div className="table_title">상담희망시간</div>
                <div className="table_contents w100">
                  <div className="clock_box">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        value={dayjs(consultingTime, 'HH:mm')}
                        onChange={(newValue) => clockHandle(newValue)}
                        format="HH:mm"
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="table_row">
              <div className="table_section">
                <div className="table_title">비고</div>
                <div className="table_contents w100">
                  <textarea className="table_textarea" value={memo} onChange={(e) => setMemo(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer_box">
            <div className="modal_btn" onClick={handleSubmit}>
              수정
            </div>
            <div className="modal_btn close" onClick={clearModal}>
              닫기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectionViewModal;
