import React, { useEffect, useState } from 'react';

const ProductDetailModal = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.modalData) {
      setData(props.modalData);
    }
  }, [props.modalData]);

  const downloadFile = () => {
    let filePath = '';

    // Determine file path based on data.product_1
    switch (data.product_1) {
      case 'VIP 패키지':
        filePath = '/files/프로그램별 검진항목_vip_프리미엄_노블레스.pdf';
        break;
      case '스마트플러스 패키지':
        filePath = '/files/SMART PLUS 검진프로그램 수정본.pdf';
        break;
      case '프리미엄 패키지':
        filePath = '/files/프로그램별 검진항목_vip_프리미엄_노블레스.pdf';
        break;
      case '노블레스 패키지':
        filePath = '/files/프로그램별 검진항목_vip_프리미엄_노블레스.pdf';
        break;
      default:
        // Default file path or handle additional cases
        filePath = '/files/국가검진 항목.pdf';
        break;
    }

    // Create an anchor element
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.split('/').pop(); // Set the file name for download
    link.click(); // Trigger the click event to start download
  };

  return (
    <div className="modal_wrap product" style={{ overflow: 'hidden', maxHeight: '100vh' }}>
      <div className="modal_back product" style={{ overflow: 'hidden' }}>
        <div className="modal_box" style={{ overflow: 'hidden', maxHeight: '100vh' }}>
          <div className="modal_title_box">
            <div className="modal_text title">{data.product_1}</div>
            {/* {data.p_key === "2" ? (
              <div className="modal_text">
                {" "}
                {Number(data.cost * 2).toLocaleString()}원 (2인)
              </div>
            ) : (
              <div className="modal_text">
                {Number(data.cost).toLocaleString()}원 (1인)
              </div>
            )} */}
            <div className="modal_text sub">
              {data.product_1 !== '국가검진 상품' && data.type !== '3' && data.type !== '4' && (
                <span>상품가격은 병원마다 상이할 수 있습니다.</span>
              )}
              {(data.type === '3' || data.type === '4') && <span>미토셀 의원 링크</span>}
            </div>
          </div>
          <div className="modal_content_box" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 220px)' }}>
            {data.product_1 === 'VIP 패키지' ? (
              <div className={`content_img vip`}></div>
            ) : data.product_1 === '스마트 패키지' ? (
              <div className={`content_img smart`}></div>
            ) : data.product_1 === '프리미엄 패키지' ? (
              <div className={`content_img premium`}></div>
            ) : data.product_1 === '노블레스 패키지' ? (
              <div className={`content_img nobless`}></div>
            ) : data.product_1 === '암+심장 정밀' ? (
              <div className={`content_img cha1`}></div>
            ) : data.product_1 === '암+뇌정밀' ? (
              <div className={`content_img cha2`}></div>
            ) : data.product_1 === '파워에이징' ? (
              <div className={`content_img cha3`}></div>
            ) : data.product_1 === '암정밀' ? (
              <div className={`content_img cha4`}></div>
            ) : data.product_1 === '심장정밀' ? (
              <div className={`content_img cha5`}></div>
            ) : data.product_1 === '페이스 라인업 수분 케어' ? (
              <div className="content_link">
                <h2>
                  <div className="naver_blog_img"></div>블로그
                </h2>
                <p
                  onClick={() => window.open('https://blog.naver.com/dlwjdtkd663690', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://blog.naver.com/dlwjdtkd663690
                  </span>
                </p>
                <h2>
                  <div className="instagram_img"></div>인스타그램
                </h2>
                <p
                  className="last-p"
                  onClick={() => window.open('https://www.instagram.com/hee__9241/', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://www.instagram.com/hee__9241/
                  </span>
                </p>
              </div>
            ) : data.product_1 === '백옥주사' ? (
              <div className="content_link">
                <h2>
                  <div className="naver_blog_img"></div>블로그
                </h2>
                <p
                  onClick={() => window.open('https://blog.naver.com/dlwjdtkd663690/223587043137', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://blog.naver.com/dlwjdtkd663690/223587043137
                  </span>
                </p>
                <h2>
                  <div className="instagram_img"></div>인스타그램
                </h2>
                <p
                  className="last-p"
                  onClick={() => window.open('https://www.instagram.com/hee__9241/', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://www.instagram.com/hee__9241/
                  </span>
                </p>
              </div>
            ) : data.product_1 === '텐써마' ? (
              <div className="content_link">
                <h2>
                  <div className="naver_blog_img"></div>블로그
                </h2>
                <p
                  onClick={() => window.open('https://blog.naver.com/dlwjdtkd663690', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://blog.naver.com/dlwjdtkd663690
                  </span>
                </p>
                <h2>
                  <div className="instagram_img"></div>인스타그램
                </h2>
                <p
                  className="last-p"
                  onClick={() => window.open('https://www.instagram.com/hee__9241/', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://www.instagram.com/hee__9241/
                  </span>
                </p>
              </div>
            ) : data.product_1 === '드레스 라인 보톡스' ? (
              <div className="content_link">
                <h2>
                  <div className="naver_blog_img"></div>블로그
                </h2>
                <p
                  onClick={() => window.open('https://blog.naver.com/dlwjdtkd663690', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://blog.naver.com/dlwjdtkd663690
                  </span>
                </p>
                <h2>
                  <div className="instagram_img"></div>인스타그램
                </h2>
                <p
                  className="last-p"
                  onClick={() => window.open('https://www.instagram.com/hee__9241/', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://www.instagram.com/hee__9241/
                  </span>
                </p>
              </div>
            ) : data.product_1 === '더마샤인(리쥬란+물광주사+스킨보톡스)' ? (
              <div className="content_link">
                <h2>
                  <div className="naver_blog_img"></div>블로그
                </h2>
                <p
                  onClick={() => window.open('https://blog.naver.com/dlwjdtkd663690/223586953397', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://blog.naver.com/dlwjdtkd663690/223586953397
                  </span>
                </p>
                <h2>
                  <div className="instagram_img"></div>인스타그램
                </h2>
                <p
                  className="last-p"
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/p/DADyViIyRAI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
                      '_blank'
                    )
                  }
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://www.instagram.com/p/DADyViIyRAI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==
                  </span>
                </p>
              </div>
            ) : data.product_1 === '상담후 진료' ? (
              <div className="content_link">
                <h2>
                  <div className="naver_blog_img"></div>블로그
                </h2>
                <p
                  onClick={() => window.open('https://blog.naver.com/dlwjdtkd663690', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://blog.naver.com/dlwjdtkd663690
                  </span>
                </p>
                <h2>
                  <div className="instagram_img"></div>인스타그램
                </h2>
                <p
                  className="last-p"
                  onClick={() => window.open('https://www.instagram.com/hee__9241/', '_blank')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <span>☞ </span>
                  <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', whiteSpace: 'nowrap' }}>
                    https://www.instagram.com/hee__9241/
                  </span>
                </p>
              </div>
            ) : (
              //국가검진
              <div className={`content_img nation`}></div>
            )}
          </div>
          <div
            className="modal_footer_box"
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              background: '#fff',
              borderTop: '1px solid #ccc',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            {/* <div
              className="modal_btn margins"
              style={{
                background: '#fff',
                color: '#4B4B4B',
              }}
              onClick={downloadFile}
            >
              <span
                onClick={() =>
                  window.open(
                    'https://map.naver.com/p/search/%EB%AF%B8%ED%86%A0%EC%85%80%EC%9D%98%EC%9B%90/place/1283472901?placePath=?entry=pll&from=nx&fromNxList=true&searchType=place&c=15.00,0,0,0,dh',
                    '_blank'
                  )
                }
                style={{ cursor: 'pointer' }}
              >
                미토셀의원 바로가기
              </span>
            </div> */}
            <div
              className="modal_btn"
              style={{
                marginRight: '10px',
                background: '#017AFF',
                color: '#fff',
              }}
              onClick={props.closeModal}
            >
              상품목록으로 돌아가기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailModal;
