import React, { useEffect, useState, useImperativeHandle, useRef } from 'react';
import Axios from 'axios';

const ProductSelect = (props, ref) => {
  const [searchOption, setSearchOption] = useState('VIP');
  const [searchInput, setSearchInput] = useState('');
  const [productList, setProductList] = useState([]);
  const [error, setError] = useState(null); // 오류 상태 추가

  useImperativeHandle(ref, () => ({
    clearSearch,
  }));

  useEffect(() => {
    getProductType();
  }, []);

  const getProductType = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/reserv/product_list');
      const allData = response.data.data;
      if (Array.isArray(allData)) {
        setProductList(allData);
      } else {
        throw new Error('Invalid data format'); // 데이터 형식이 유효하지 않을 경우 오류 발생
      }
    } catch (error) {
      console.error('Error fetching list:', error);
      setError('상품 목록을 불러오는 데 실패했습니다.'); // 오류 메시지 설정
    }
  };

  const handleSearch = () => {
    if (props.setSearchData) {
      const searchData = {
        name_1: searchOption,
        name_2: searchInput,
      };

      props.setSearchData(searchData);
    }
  };

  const clearSearch = () => {
    if (props.setSearchData) {
      props.setSearchData([]);
      setSearchOption('VIP');
      setSearchInput('');
    }
  };

  return (
    <div className="list_select_area">
      <div className="search_select">
        <div className="search_input_container product">
          <select className="list_select" value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
            <option value="">상품명</option>
            {Array.isArray(productList) && productList.length > 0 ? ( // productList가 유효한 배열인지 확인
              productList.map((data, index) => (
                <option key={index} value={data.product_1}>
                  {data.product_1}
                </option>
              ))
            ) : (
              <option value="">상품이 없습니다.</option> // 상품이 없을 때 메시지
            )}
          </select>
          <div className="search_input">
            <div className="list_search" onClick={() => handleSearch()}>
              검색
            </div>
            <div className="list_search reset_btn" onClick={() => clearSearch()}>
              초기화
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(ProductSelect);
