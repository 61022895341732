import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import ApexCharts from 'apexcharts';
import 'react-calendar/dist/Calendar.css';

const SalesHospital = () => {
  const [arrayData, setArrayData] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalAverage, setTotalAverage] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getSalesData();
    getChartData();
  }, []);

  const getSalesData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/sales_hospital');
      const data = response.data.data;
      setArrayData(data);

      if (data && data.length > 0) {
        setSelectedHospital(data[0].name);
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        renderApexChart(data, data[0].name, firstDayOfMonth, lastDayOfMonth);
      }
    } catch (error) {
      console.error('Error fetching list:', error);
    }
  };

  const getChartData = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/sales_chart_data');
      const chartData = response.data.data;
      console.log('chartData', chartData);
      setChartData(chartData);
    } catch (error) {
      console.log('chart data 호출 error', error);
    }
  };

  const renderApexChart = (data, hospitalName, startDateObj, endDateObj) => {
    const labels = [];
    const seriesData = [];
    const currentDate = new Date(startDateObj);

    // 날짜별 초기화
    while (currentDate <= endDateObj) {
      labels.push(`${currentDate.getDate()}일`);
      seriesData.push({ commission: 0, cost: 0 }); // commission과 cost 초기화
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const filteredData = data.filter((hospital) => hospital.name === hospitalName);

    filteredData.forEach((hospital) => {
      const hospitalDate = new Date(hospital.date);
      const dayIndex = (hospitalDate - startDateObj) / (1000 * 60 * 60 * 24);

      if (dayIndex >= 0 && dayIndex < seriesData.length) {
        seriesData[dayIndex].commission += hospital.commission || 0;
      }
    });

    // chartData에서 cost를 날짜에 맞춰 합산
    const costDataMap = {};
    chartData.forEach((dataPoint) => {
      const dateKey = new Date(dataPoint.date).toDateString();
      if (!costDataMap[dateKey]) {
        costDataMap[dateKey] = 0;
      }
      costDataMap[dateKey] += dataPoint.cost || 0; // cost 합산
    });

    // seriesData에 cost 값 추가
    seriesData.forEach((dataPoint, index) => {
      const dateKey = new Date(startDateObj);
      dateKey.setDate(dateKey.getDate() + index);
      dataPoint.cost = costDataMap[dateKey.toDateString()] || 0; // 해당 날짜의 cost 값
    });

    const totalCommission = seriesData.reduce((acc, cur) => acc + cur.commission, 0);
    const totalCost = seriesData.reduce((acc, cur) => acc + cur.cost, 0); // 총 cost

    setTotalCommission(totalCommission);

    const options = {
      series: [
        {
          name: '병원/상품별 그래프',
          type: 'column',
          data: seriesData.map((data) => data.commission),
        },
        {
          name: '총 상품구매금액 그래프', // cost 그래프 추가
          type: 'line',
          data: seriesData.map((data) => data.cost),
        },
      ],
      chart: {
        type: 'area',
        height: 350,
      },
      stroke: {
        width: [0, 4], // line 그래프의 두께 설정
      },
      title: {
        text: `[ ${hospitalName} 커미션차트 ]`,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex }) {
          // cost 시리즈일 때 "원" 추가, 0일 경우에는 제외
          if (seriesIndex === 1) {
            return val > 0 ? `${val.toLocaleString()} 원` : '0'; // 0이면 그냥 0 출력
          }
          return val.toLocaleString(); // commission 시리즈
        },
        enabledOnSeries: [0, 1],
      },
      labels: labels,
      xaxis: {
        categories: labels,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val.toLocaleString(); // 천 단위로 콤마 추가
          },
        },
      },
    };

    const existingChart = document.querySelector('#chart');
    if (existingChart) {
      existingChart.remove();
    }

    const newChartContainer = document.createElement('div');
    newChartContainer.id = 'chart';
    document.querySelector('.list_area').appendChild(newChartContainer);

    const chart = new ApexCharts(newChartContainer, options);
    chart.render();
  };

  const handleTabClick = (hospitalName) => {
    setSelectedHospital(hospitalName);
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    renderApexChart(arrayData, hospitalName, firstDayOfMonth, lastDayOfMonth);
  };

  const handleSearch = () => {
    if (!selectedHospital) {
      alert('병원을 선택해 주세요.');
      return;
    }
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    renderApexChart(arrayData, selectedHospital, startDateObj, endDateObj);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setTotalCommission(0);
    setTotalAverage(0);

    if (arrayData.length > 0) {
      const defaultHospital = arrayData[0].name;
      setSelectedHospital(defaultHospital);
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      renderApexChart(arrayData, defaultHospital, firstDayOfMonth, lastDayOfMonth);
    }
  };

  const uniqueHospitals = [...new Set(arrayData.map((hospital) => hospital.name))];

  return (
    <div className="main_wrap">
      <div className="main_back">
        <div className="main_title_box">[ 병원별 검진고객 현황 ]</div>
        <div className="board_list_wrap chart">
          <div className="list_area chart">
            <div className="search_box">
              <div className="list_select_area">
                <div className="search_select">
                  <select className="list_select" onChange={(e) => handleTabClick(e.target.value)}>
                    <option value="">병원명</option>
                    {uniqueHospitals.map((hospital) => (
                      <option key={hospital} value={hospital}>
                        {hospital}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="list_select_area">
                <div className="search_select">
                  <input
                    type="date"
                    className="list_select"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  ~&nbsp;&nbsp;&nbsp;
                  <input
                    type="date"
                    className="list_select"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="search_input">
                  <div className="list_search" onClick={handleSearch}>
                    검색
                  </div>
                  <div className="list_search reset_btn" onClick={handleReset}>
                    초기화
                  </div>
                </div>
              </div>
            </div>
            <div className="hospital-tab-container">
              {uniqueHospitals.map((hospital) => (
                <div
                  key={hospital}
                  className={`hospital-tab ${hospital === selectedHospital ? 'active' : ''}`}
                  onClick={() => handleTabClick(hospital)}
                >
                  {hospital}
                </div>
              ))}
            </div>
            <div id="chart"></div>
            <div className="chart-totals">
              <div>[ 토탈 수익금 ]: {totalAverage.toLocaleString()} 원</div>
              <div>[ 총 커미션 ]: {totalCommission.toLocaleString()} 원</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesHospital;
