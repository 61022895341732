import React, { useEffect, useState, useImperativeHandle, useRef } from 'react';
import { useBranchContext } from '../../Context/BranchContext';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const MemberSelect = (props, ref) => {
  const { typeGroup, companyGroup, branchGroup, setContextType, setContextCompany } = useBranchContext();
  const [type, setType] = useState('');
  const [company, setCompany] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchIdx, setBranchIdx] = useState('');
  const [startDate, setStartDate] = useState(null); // 가입일1
  const [endDate, setEndDate] = useState(null); // 가입일2
  const [openCalendar, setOpenCalendar] = useState({
    start: false,
    end: false,
  });

  useImperativeHandle(ref, () => ({
    clearSearch,
  }));
  useEffect(() => {
    setContextType(type);
  }, [type]);

  useEffect(() => {
    setContextCompany(company);
  }, [company]);

  const selectBranch = (num) => {
    setBranchIdx(num);
    const selectedBranch = branchGroup.find((data) => data.branch_idx === num);
    if (selectedBranch) {
      setBranchName(selectedBranch.branch_name);
    }
  };

  const selectType = (data) => {
    setType(data);
    setCompany('');
    setBranchIdx('');
    setBranchName('');
  };

  const handleSearch = () => {
    if (props.setSearchData) {
      props.setSearchData([
        {
          branch_type: type,
          company_name: company,
          branch_name: branchName,
          start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
          end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
        },
      ]);
    }
  };

  const clearSearch = () => {
    //console.log("탭변경");
    if (props.setSearchData) {
      props.setSearchData([]);
      selectType('');
      setStartDate(null);
      setEndDate(null);
    }
  };

  const setDateRange = (date, isStart) => {
    if (isStart) {
      setStartDate(date);
      setEndDate(null); // 또는 필요에 따라 endDate를 설정합니다.
    } else {
      setEndDate(date);
    }
    setOpenCalendar({ start: false, end: false }); // 날짜 선택 후 달력 닫기
  };

  const toggleCalendar = (type) => {
    setOpenCalendar({
      start: type === 'start' ? !openCalendar.start : false,
      end: type === 'end' ? !openCalendar.end : false,
    });
  };

  return (
    <div className="list_select_area">
      <div className="search_select">
        <select className="list_select" value={type} onChange={(e) => selectType(e.target.value)}>
          <option value="">지점종류</option>
          {typeGroup.map((type, index) => {
            return (
              <option key={index} value={type}>
                {type}
              </option>
            );
          })}
        </select>
        <select className="list_select" value={company} onChange={(e) => setCompany(e.target.value)}>
          <option value="">회사명</option>
          {companyGroup.map((data, index) => {
            return (
              <option key={index} value={data}>
                {data}
              </option>
            );
          })}
        </select>
        <select className="list_select" value={branchIdx} onChange={(e) => selectBranch(e.target.value)}>
          <option value="">지점명</option>
          {branchGroup.map((data, index) => {
            return (
              <option key={index} value={data.branch_idx}>
                {data.branch_name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="search_input">
        {/* <input
                  className="list_input"
                  placeholder="검색어를 입력하세요"
                ></input> */}
        <div className="list_search" onClick={() => handleSearch()}>
          검색
        </div>
        <div className="list_search reset_btn" onClick={() => clearSearch()}>
          초기화
        </div>
      </div>
      <div className="custom-wrap">
        <div className="custom-search">
          <input
            className="table_input"
            type="text"
            placeholder="등록일1"
            value={startDate ? moment(startDate).format('YYYY.MM.DD') : ''}
            onClick={() => toggleCalendar('start')}
            readOnly
          />
          {openCalendar.start && (
            <Calendar
              className="modal_calendar"
              onChange={(date) => setDateRange(date, true)}
              value={startDate}
              formatDay={(locale, date) => moment(date).format('DD')}
              minDate={moment().toDate()}
              calendarType="gregory"
            />
          )}
        </div>
        &nbsp;~&nbsp;
        <div className="custom-search">
          <input
            className="table_input"
            type="text"
            placeholder="등록일2"
            value={endDate ? moment(endDate).format('YYYY.MM.DD') : ''}
            onClick={() => toggleCalendar('end')}
            readOnly
          />
          {openCalendar.end && (
            <Calendar
              className="modal_calendar"
              onChange={(date) => setDateRange(date, false)}
              value={endDate}
              formatDay={(locale, date) => moment(date).format('DD')}
              minDate={moment().toDate()}
              calendarType="gregory"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(MemberSelect);
